var searchPage = {
	init : function () {
		var _this = this;
		if (!this.isOnSearchPage()) return;
		console.log("searchPage.init()");

		this.loadFirstPage();

		// $('.grid').imagesLoaded(function() {
		// 	console.log("imagesLoaded");
			_this.applyMasonry();
			_this.applyInfiniteScroll(true);
		// });
		this.initListItemListeners();
		this.initOnChangeHandlers();
		this.initDomChangeListener();
		this.initPageSubmit();		
		
	},
	loadFirstPage : function() {
		console.log("searchPage.loadFirstPage()",$("link[rel=canonical]").attr('href'));
	},
	initOnChangeHandlers : function() {
		var _this = this; 
		//reworked 
		$('#form-search-options [data-reload-on-change=true]').on('change',function(e) {
			_this.submitFilterForm(this,false);
		});
	},
	initPageSubmit : function() {
		//inline loading, which means to load while not really reloading the page is weird now, because we have other items too next to user results
		//so for now inline will not be done
		var _this = this;		
		$('#form-search-options').submit(function (event) {
			event.preventDefault();
			var url = $(event.target).attr('action') + "?" + decodeURI(decodeURI($(event.target).serialize()));
			if ($(event.target).data('inline')) {
				$('.experts-content').append("<div id='inline-loader' class='loader loading'></div>"); //todo
				$( "#pagination").load( url + " #pagination > *", function (response,status,xhr) {
					if (status == "success") {
						$("#form-search-options").data('inline',false);
		                var page = (new DOMParser()).parseFromString(response, "text/html");

		               	_this.reloadMasonryItems(page);
						//load locations onto the map
    					_this.parseLocationsAndShow(page);

					} else {
						console.log("error occured loading content");
					}
					$('#inline-loader').remove();
				} );
			} else {
				document.location.href = url;
			}
		});

		$('#form-search-options').submit(function (event) {
			if ($(event.target).data('inline')) {
				event.preventDefault();
				$('body').append("<div id='loader loading'></div>");
				$('.loader').addClass('loading');
				var url = $(event.target).attr('action') + "?" + $(event.target).serialize();
				$( "#pagination").load( url + " #pagination > *", function (response,status,xhr) {
					if (status == "success") {
		                console.log('going inline',response);
						$("#form-search-options").data('inline',false);
		                var page = (new DOMParser()).parseFromString(response, "text/html");

		               	_this.reloadMasonryItems(page);
						//load locations onto the map
    					_this.parseLocationsAndShow(page);

					} else {
						console.log("error occured loading content");
					}
					$('.loader').removeClass('loading');
				} );
			}
		});
	},
	initDomChangeListener : function() {
		console.log("initDomChangeListener()");
		var _this = this;
		var targetNodes         = $(".article-content");
		var MutationObserver    = window.MutationObserver || window.WebKitMutationObserver;
		var obsConfig           = { childList: true, characterData: true, attributes: true, subtree: true };
		var myObserver          = new MutationObserver (function(mutationRecords) {
			mutationRecords.forEach ( function (mutation) {
	    	if (mutation.type == "childList" && typeof mutation.removedNodes == "object") {
	            var removedNodes = $(mutation.removedNodes);
				if (removedNodes.is("blockquote.twitter-tweet")) {				
					console.log("tweet_embedded");
					_this.applyMasonry();
				}
	    	}
	    } );
		});

		//--- Add a target node to the observer. Can only add one node at a time.
		targetNodes.each ( function () {
		    myObserver.observe (this, obsConfig);
		} );
	},
	initListItemListeners : function() {
		console.log("initListItemListeners()");
    	$('.expert-result-item').off('mouseover');
    	$('.expert-result-item').on('mouseover',function(e) {
    		$(this).addClass("hovering");
    	});
    	$('.expert-result-item').off('mouseout');
    	$('.expert-result-item').on('mouseout',function(e) {
    		$(this).removeClass("hovering");
    	});
    	$('.call-button').off('mouseover');
    	$('.call-button').on('mouseover',function(e) {
    		e.stopPropagation();
    		$(this).addClass("hovering");
    	});
    	$('.call-button').off('mouseout');
    	$('.call-button').on('mouseout',function(e) {
    		$(this).removeClass("hovering");
    	});
	},
	parseLocationsAndShow: function(page,append) {
		console.log("parseLocationsAndShow()");
		if (page && $(page).find('#locations').val()) {
			toMapMarkerHandler.showLocations(JSON.parse($(page).find('#locations').val()),append);
		}
	},
	applyInfiniteScroll: function(initial) {
		var lastGridItemPosition = $(".expert-result-item").length > 0 ? $(".expert-result-item").last().position().top : 0;
		console.log('applyInfiniteScroll',initial,lastGridItemPosition);
		var _this = this;
		
		if (!initial) $('.grid').infiniteScroll('destroy');
		if (!this.hasMoreThanOnePage()) {
			$('#pagination').hide();
			$('#page-loader').hide();
		} else {
			$('.grid').infiniteScroll({
				path: 'link[rel=next]',
				append: ".grid-item.expert-result-item",
				history: false,
				scrollThreshold: lastGridItemPosition + 3000,
				// elementScroll: '.expert-result-item',
				status: '#page-loader',
				loadOnScroll: true,
				outlayer:$('.grid').data('masonry'),
				debug:true,
			});
			$('.grid').on( 'load.infiniteScroll', function( event, page, path ) {
				console.log( 'load.infiniteScroll: ',page );
				_this.parseLocationsAndShow(page,true);
			});
		}
	},
	applyMasonry : function () {
		console.log("applyMasonry");
		var _this = this;
		var $grid = $('.grid').masonry({
			itemSelector: ".grid-item",
			columnWidth: '.grid-sizer',
			percentPosition:true,
			stamp:".stamp",
			visibleStyle: { transform: 'translateY(0)', opacity: 1 },
			hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
		});
	},
	reloadMasonryItems: function(page) {
		var _this = this;
		//replace masonry items
		var $oldItems = $('.grid-item');
		var $newItems = $(page).find('.grid-item');
		$('.grid').masonry( 'remove', $oldItems);
		$('.grid').append($newItems).masonry('appended', $newItems);
		$('.grid').masonry('layout');
		//$('.grid').masonry('reloadItems');
		// $('.grid').imagesLoaded(function() {
			$('.grid').masonry('layout');
			// _this.applyInfiniteScroll();
			// _this.initListItemListeners();
		// });

	},
	isOnSearchPage: function () {
		var result = true;
		var body = $("#app-layout.experts");
		if (body == undefined || body.length <= 0)  result = false;
		console.log("isOnSearchPage",result);
		return result;
	},
	hasMoreThanOnePage: function () {
		var result = true;
		var next = $("link[rel=next]");
		if (next == undefined || next .length <= 0)  result = false;
		console.log("hasMoreThanOnePage",result);
		return result;
	},
	submitFilterForm: function(formElementChanged, inline) {
		inline = inline == undefined ? true : false;
		$('#form-search-options').data('inline',inline);

		if ($(formElementChanged).attr('name') == 'show_map' && $(formElementChanged).is(':checked')) {
			if ($('input[name=\'criteria[bounds]\']').val()) {
				$('<input>', {
					type: 'hidden',
					id: 'filters[bounds]',
					name: 'filters[bounds]',
					value: $('input[name=\'criteria[bounds]\']').val(),
				}).appendTo('#form-search-options');
				$('#form-search-options').submit();
			} else {
				if (typeof toMapHandler !== "undefined") {
					toMapHandler.getUserLocation(function(location) {
						var bounds = toMapHandler.boundsForLatLngObject(location);
						$('<input>', {
							type: 'hidden',
							id: 'filters[bounds]',
							name: 'filters[bounds]',
							value: bounds.join(','),
						}).appendTo('#form-search-options');
						$('#form-search-options').submit();
					});
				}
			}
		} else {
			$('input[name=\'filters[bounds]\']').val('');
			$('input[name=\'filters[bounds]\']').remove();
			console.log('submitting');
			$('#form-search-options').submit();
		}

	},
	loadPageSection: function(sectionId,url,afterMoreSectionId) {
		console.log("loadPageSection",sectionId);
		var _this = this;
		var nextButton = $(sectionId).next('.show-more');
		nextButton.html('...').attr('disabled','disabled');

		$.get( url, function( data ) {
			nextButton.remove();

			var page = $($.parseHTML(data));
			var contentToAppend = page.find(sectionId).html();
			var nextButtonToAppend = page.find(sectionId).next('.show-more');
			var appendTo = $(sectionId);
			appendTo.append(contentToAppend);
			if(nextButtonToAppend) {
				appendTo.after(nextButtonToAppend);
			}

			if (afterMoreSectionId) {			
				var afterMore = $("#"  + afterMoreSectionId);
				var afterMoreContent = page.find("#"  + afterMoreSectionId).html();
				console.log('afterMore',afterMore,afterMoreContent);
				if (afterMore.length && afterMoreContent) {
					afterMore.append(afterMoreContent);
				}
			}

			_this.applyMasonry();
			// $('.grid').imagesLoaded(function() {
			// 	console.log('.grid imagesLoaded');
			// 	_this.applyMasonry();
			// });
		});
	},
};

$(document).ready(function() {
	searchPage.init();

});
